import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '~/components/molecules/Layout';
import { SEO } from '~/components/atoms/SEO';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import { TranslationContext } from '~/utils/styles/utils';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title="Seite nicht gefunden - Neurozentrum Arabellapark"
        metaDescription="Neurologie Bogenhausen München: Dr. Sabine Uez ist Ihre Spezialistin für Erkrankungen des Nervensystems. Vereinbaren Sie hier einen Termin."
      />
      <TranslationContext.Provider value={t}>
        <NotFoundPage />
      </TranslationContext.Provider>
    </Layout>
  );
};

export const useTranslationContext = () => React.useContext(TranslationContext);

export default NotFound;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["fearpage"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
